.slide-container {
    width: 250px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.slide-item {
    margin-bottom: 15px;
}

.slide-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 18px; /* Aumenta o tamanho da fonte */
}

.slide-item input[type="range"] {
    width: 100%;
}

@media (max-width: 740px) {
    .slide-container {
        display: none;
    }
}
