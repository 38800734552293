.dsm-monitor-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.dsm-monitor-justify {
    width: 100%;
    height: 100px;
    align-content: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.flex-container {
    display: flex;
    align-items: center;
}

.dsm-monitor-center {
    display: flex;
    justify-content: center;
    align-items: center;
}



