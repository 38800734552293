.convoy-image {
    width: 280px;
    height: 150px;
    border: var(--text-color) 2px solid;
    border-radius: 5%;
}

.title-message {
    font-weight: bold;
    font-size: large;
    color: var(--text-color);
}

.convoy-dialog {
    width: 700px;
}

.convoy-alert-text {
    font-size: 14px;
}

.convoy-alert-title {
    font-size: large;
}