.categoria {
    padding: 0.8rem;
    border: var(--text-color) solid 1px;
    border-radius: 5px 10px;
    margin-bottom: 1%;
}

.titulo-categoria {
    font-size: 1.2rem;
    font-style: oblique;
    font-weight: bold;

}

.field-validation {
    color: var(--text-color);

}