.leaflet-popup-content-wrapper:has(.camera-card-container) {
    visibility: hidden; /* Torna o wrapper invisível */
}

/* Faz com que o .camera-card-container permaneça visível */
.leaflet-popup-content-wrapper:has(.camera-card-container) .camera-card-container {
    visibility: visible;
}


.camera-card-container {
    min-width       : 480px;
    border-radius   : 8px;
    background-color: rgba(120, 69, 238, 0.5);
    visibility      : visible; /* Garante que este conteúdo seja visível */
    padding         : 0.5rem;
}

.camera-card-border {
    background-color: rgba(69, 238, 83, 0.5);
    visibility      : visible; /* Garante que este conteúdo seja visível */
    color           : black;
    font-size       : 14px;
    border-radius   : 5px;
    box-shadow      : 0 0 10px rgba(0, 0, 0, 0.5);
    text-align      : center;
    padding         : 1rem;
}

.camera-title {
    text-align: center;
    margin-bottom: 20px;
}

.content-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

.left-section, .mid-section, .right-section {
    flex: 1 1 calc(33.33% - 1rem); /* Três colunas de tamanho igual */
    max-width: calc(33.33% - 1rem); /* Ajuste para espaçamento */
    box-sizing: border-box;
    overflow: hidden; /* Evita o transbordamento nas colunas */
    text-align: center;
}

.left-section img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.right-section div,
.mid-section div {
    white-space: nowrap; /* Impede a quebra de linha */
    overflow: hidden;
    text-overflow: ellipsis; /* Trunca o texto que não cabe */
}
