.image-dss-event {
    width: 350px;
    height: 350px;
    border: 1px var(--text-color) solid;
    border-radius: 20px;
}

.dss-plate{
    font-weight: bold;
    font-size: large;
}