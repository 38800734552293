.performance-graph {
    height: 400px; /* Altura desejada */
}

.performance-graph canvas {
    height: 100% !important; /* Força o canvas a ocupar 100% da altura do contêiner */
}


@media screen and (max-width: 1400px) {
    .performance-graph {
        display: none !important;
    }
}
