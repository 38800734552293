.card-icon {
    height: 2.5rem;
    width: 2.5rem;
}

.card-title {
    font-weight: bolder;
    color: var(--text-color);
}


