.exception-card {
    height: auto;
}

.exception-title {
    color: var(--text-color);
}

.exception-body {
    color: var(--text-color);
    overflow-wrap: break-word;
    max-width: 100%;
    font-size: 12px;
    @media screen and (max-width: 1500px) {
        font-size: 10px;
    }
}

.exception-body-div {
    overflow-y: hidden;
    max-width: 90%;
}