.map-container .vehicle-route .p-dialog {
    width: auto;
    display: flex;
    max-width: 100vw; /* Define uma largura máxima para evitar que ocupe toda a tela */
    min-width: 500px; /* Define uma largura mínima para evitar que fique muito pequeno */
    max-height: 100vh; /* Define uma largura máxima para evitar que ocupe toda a tela */
    min-height: 30vh; /* Define uma largura mínima para evitar que fique muito pequeno */
}

.map-container .vehicle-route .p-dialog-content {
    width: 80vw;
    max-height: 70vh; /* Define uma largura máxima para evitar que ocupe toda a tela */
    min-height: 30vh; /* Define uma largura mínima para evitar que fique muito pequeno */
}

.map-container .vehicle-route .p-dialog-header {
    width: 80vw;
}


.map-container .vehicle-route strong {
    padding: 10px;
}


