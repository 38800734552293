.map-container .side-bar {
    top             : 0px;
    left            : 50px;
    position        : absolute;
    z-index: 1000;
    transition: width 0.3s;
    overflow: hidden;
    border-radius: 5px;
    background-color:#888;
}

.map-container .side-bar.open {
    width: 250px; /* Largura da barra lateral expandida */
}

.map-container .side-bar.closed {
    width: 60px; /* Largura da barra lateral retraída */
}

.map-container .toggle-button {
    background-color: #888;
    color: white;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;
}

.map-container .side-bar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    padding: 10px;
}

.map-container .side-bar-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    color: #333;
    background-color: #eaeaea;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.map-container .side-bar-item:hover {
    background-color: #d4d4d4;
}

.map-container .side-bar.closed .side-bar-item span {
    display: none; /* Oculta o texto quando a barra está retraída */
}

.map-container .side-bar-item i {
    font-size: 1.2em;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-container .side-bar.closed .side-bar-item i {
    margin-right: 0; /* Remove o espaçamento extra ao exibir apenas os ícones */
    display: flex;
    justify-content: center;
}
