.layout-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 4rem;
    transition: margin-left $transitionDuration;
    max-width: 100vw;
    height: calc(100vh - (var(--app-topbar-height) + var(--app-footer-height)));
}

.layout-main {
    flex-grow: 1;
}
