.page-header {
    top             : 20px; /* Fixado no topo da tela */
    left            : 50%;
    transform       : translateX(-50%); /* Centraliza horizontalmente */
    position        : absolute;
    z-index         : 1000;
    transition      : width 0.3s;
    overflow        : hidden;
    border-radius   : 5px;
    background-color: rgba(0, 0, 0, 0.5);

}

.page-header .page-header-title {
    padding         : 10px 20px; /* Espaçamento interno para melhorar o design */
    text-align      : center; /* Centraliza o texto */
    font-size       : 40px; /* Ajuste o tamanho da fonte conforme necessário */
    color           : #ffffff; /* Cor do texto */
}
