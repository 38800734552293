.no-plate-image {
    width: 180px;
    height: 150px;
    border: var(--text-color) 2px solid;
    border-radius: 5%;
}

.title-message {
    font-weight: bold;
    font-size: large;
    color: var(--text-color);
}

.no-plate-text {
    font-size: 14px;

    &__Parameter {
        display: block;
    }
}

.no-plate-title {
    font-size: large;
}


