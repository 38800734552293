/* legend.css */
.legend {
    bottom          : 20px;
    left            : 50px;
    position        : absolute;
    z-index         : 1000;
    transition: width 0.3s;
    overflow: hidden;
    border-radius: 5px;
    background-color: rgba(136, 136, 136, 0.5);
    color: black;
}

.legend-container {
    padding: 10px;
    background-color:  rgba(255, 255, 255, 0);
    border-radius: 8px;
    max-width: 300px;
}


.legend-container div {
    font-size: 16px;
}

.color-box {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
}
