.login-container {
    overflow: auto hidden;
}

.login-card {
    display: flex;
    justify-content: center;
    max-width: 600px;
    width: 100%;

}

.login-content {
    position: relative;
    left: 0%;
    top: 45%;
    width: 100%;
}

.login-image {
    width: 100%;
    height: 100px;
    border-radius: 0%;
}

.login-field-group {
    width: 100%;
}

.remember-password-group {
    width: 80%;
}

.remember-password {
    color: var(--text-color);

    @media screen and (max-width: 1000px) {
        display: block;
    }
}

.forgot-password {
    display: block;
}

.login-field {
    width: 100%;
    display: block;
}

.image-container {
    position: absolute;
    left: 50%;
    top: 20%;

    @media screen and (min-width: 3000px) {
        left: 63%;
    }
}

.image-container-2 {
    position: absolute;
    right: 40%;
    top: 20%;

    @media screen and (min-width: 3000px) {
        left: -60%;
    }
}

.image-moon {
    height: 750px;
    width: 600px;
}

.login-field-description {
    color: var(--text-color);
    font-style: bold;
}

.split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
    background-color: var(--surface-d);
    right: 25%;
    @media screen and (max-width: 1400px) {
        width: 100%;
        right: 0%;
    }
}

.borders {
    height: 100%;
    width: 25%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;

    @media screen and (max-width: 1400px) {
        display: none;
    }
}

.start {
    left: 0;
    background-color: rgba(255, 255, 255, 0.87);
}

.end {
    right: 0;
    background-color: rgba(255, 255, 255, 0.87);
}



.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: start;
}

.clip-loader-container {
    text-align: center;
}

.link-button {
    cursor: pointer;
    text-decoration: none; /* Remove o sublinhado padrão */
    color: #007bff; /* Altere para a cor desejada */
}

.link-button:hover {
    text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
}
