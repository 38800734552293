.event-convoy-image {
    width: 180px;
    height: 150px;
    border: var(--text-color) 2px solid;
    border-radius: 5%;
}

.title-message {
    font-weight: bold;
    font-size: large;
    color: var(--text-color);
}

.convoy-text {
    font-size: 14px;

    &__Parameter {
        display: block;
    }
}

.convoy-title {
    font-size: large;
}

.convoy-plate {
    justify-content: center;
    display: flex;
    font-size: 30px;
}

.convoy-plate-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
