.map-container {
    position       : relative;
    width          : 100%;
    height         : calc(100% - (var(--app-topbar-height) + var(--app-footer-height)));
    overflow       : hidden;
    max-width      : 100vw;
    max-height     : 100vh;
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : 100%;

    background-color: #f0f0f0;
}

.map-content {
    position: absolute;
    width   : 100%;
    height  : 100%;
}

.leaflet-map-container {
    width : 100%;
    height: 100%;
}

.leaflet-container {
    width  : 100%;
    height : 100%;
    display: block;
}

.map-wrapper {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 100%;
}


.leaflet-tooltip {
    background-color: rgba(128, 128, 128, 0.5);
    color           : black;
    font-size       : 14px;
    font-weight     : bold;
    padding         : 5px 10px;
    border-radius   : 5px;
    box-shadow      : 0 0 5px rgba(0, 0, 0, 0.5);
}

.leaflet-pane {
    background-color: rgba(224, 10, 10, 0.8);
}

.leaflet-popup-tip {
    background-color: rgba(128, 128, 128, 0.8);
}

.leaflet-bottom {
    bottom: 70px;
}

.leaflet-bottom .legend {
    padding         : 10px;
    border-radius   : 15px;
    color           : black;
    background-color: white;
    box-shadow      : '0 0 15px rgba(0, 0, 0, 0.3)';
    font-size       : 16px;
    bottom          : 0.2rem;
    right           : 0.1rem;
}

.leaflet-container button {
    position        : absolute;
    z-index         : 1000;
    font-size       : 16px;
    padding         : 10px;
    background-color: #fff;
    border-radius   : 5px;
    box-shadow      : 0 0 10px rgba(0, 0, 0, 0.7);
    cursor          : pointer;
    display         : flex;
    align-items     : center;
    justify-content : center;
    gap             : 8px;
    /* Espaço entre o ícone e o texto */
    top             : 1rem;
    right           : 0.5rem;
}

.leaflet-container .button-route button {
    position        : absolute;
    z-index         : 1000;
    font-size       : 16px;
    padding         : 10px;
    border-radius   : 5px;
    box-shadow      : 0 0 10px rgba(0, 0, 0, 0.7);
    cursor          : pointer;
    display         : flex;
    align-items     : center;
    justify-content : center;
    gap             : 8px;
    /* Espaço entre o ícone e o texto */
    top             : 6rem;
    right           : 0.5rem;
    background-color: #fcad03;
}

.leaflet-popup-content-wrapper {
    width           : auto;
    background-color: rgba(16, 29, 99, 0.5);
}

.leaflet-popup-content {
    background-color: rgba(69, 238, 83, 0.5);
    border-radius   : 5px;
    box-shadow      : 0 0 10px rgba(0, 0, 0, 0.5);
    margin          : 0.5rem;
    font-size       : large;
}

/* Estilo para a imagem capturada do veículo */
.vehicle-image-container {
    width     : 100%;
    /* Ocupa toda a largura do card */
    text-align: center;
}

.vehicle-image-container img {
    width        : 100%;
    max-width    : 15rem;
    /* Limite de largura para a imagem */
    height       : auto;
    border-radius: 8px;
    box-shadow   : 0px 4px 6px rgba(0, 0, 0, 0.1);
}


@media (max-width: 740px) {

    .leaflet-bottom {
        bottom: 0px;
    }

    .leaflet-bottom .legend {
        display: none;
    }

    .leaflet-container button {
        position        : absolute;
        z-index         : 1000;
        font-size       : 8px;
        padding         : 10px;
        background-color: #fff;
        border-radius   : 5px;
        box-shadow      : 0 0 10px rgba(0, 0, 0, 0.5);
        cursor          : pointer;
        display         : flex;
        align-items     : center;
        justify-content : center;
        gap             : 8px;
        /* Espaço entre o ícone e o texto */
        top             : 0px;
        right           : 0px;
    }

    .leaflet-container .button-route button  {
        position        : absolute;
        z-index         : 1000;
        font-size       : 8px;
        padding         : 10px;
        background-color: #fff;
        border-radius   : 5px;
        box-shadow      : 0 0 10px rgba(0, 0, 0, 0.5);
        cursor          : pointer;
        display         : flex;
        align-items     : center;
        justify-content : center;
        gap             : 8px;
        /* Espaço entre o ícone e o texto */
        bottom          : 0px;
        right           : 0px;
    }

}
