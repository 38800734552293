.map-container .p-dialog {
    display: flex;
    width: auto; /* Permite ajuste automático ao conteúdo */
    max-width: 40vw; /* Define uma largura máxima para evitar que ocupe toda a tela */
    min-width: 300px; /* Define uma largura mínima para evitar que fique muito pequeno */
}

.map-container .dropdown-filters {
    background-color: rgba(255, 255, 255, 0);
    width: fit-content; /* Ajusta a largura com base no conteúdo */
}

.map-container .dropdown-filters .p-fieldset {
    background-color: rgba(0, 0, 0, 0.5);
    width: fit-content; /* Ajusta o fieldset ao conteúdo */
}

.map-container .dropdown-filters .p-fieldset-content {
    display        : flex;
    align-items    : center;
    justify-content: center;
    padding: 0.1rem;
}

.map-container .dropdown-filters .p-fieldset-content .p-input-group{
    padding:0.1rem;
}

.map-container .dropdown-filters .p-component {
    font-size: 12px;
}

.map-container .dropdown-filters .p-dropdown-label {
    font-size: 12px;
}

.map-container .dropdown-filters .p-multiselect-token-label {
    font-size: 14px;
}

