.camera-information {
    font-size: 12px;
    color: var(--text-color);
}

.image-card {
    width: 180px;
    height: 108px;
    border: 2px solid black;
    border-radius: 5%;

    @media screen and (max-width:1500px) {
        width: 100px;
        height: 80px;
    }
}

.card-body {
    display: flex;
}

.vertical-bar {
    height: 108px;
    border-left: 2px var(--text-color) solid;

    @media screen and (max-width:1500px) {
        height: 80px;
    }

    &__convoy {
        border-left: 2px var(--text-color) solid;
        height: 162px;
    }
}

.informacoes-veiculo-i {
    display: block;
}

.vehicle-big-details-error {
    font-size: 20px;
    color: red;
    font-weight: bold;
}

.vehicle-big-details {
    font-size: 20px;
    color: var(--text-color);
    font-weight: bold;

    @media screen and (max-width:1500px) {
        font-size: 14px;
    }
}

.vehicle-small-details {
    font-size: 12px;
    color: var(--text-color);

    @media screen and (max-width:1500px) {
        font-size: 10px;
    }
}

.vehicle-visualization-card {
    max-height: 100px;
    height: 400px;
    overflow-y: scroll auto;
}

.vehicle-visualization-card-container {
    @media screen and (min-width:900px) {
        position: absolute;
    }

    overflow-y: scroll;
    max-height: 37rem;
}

.vehicle-information {
    width: 100%;
}

::-webkit-scrollbar {
    width: 5px;

}

::-webkit-scrollbar-track {
    background: var(--surface-f);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.vehicle-smaller-details {
    font-size: 12px;
    color: var(--text-color);

    @media screen and (max-width:1500px) {
        font-size: 10px;
    }

    &__error {
        color: red;
    }

    &__success {
        color: green;
    }
}

.vehicle-really-small-detail {
    color: var(--text-color)
}
