.point-ab-image {
    width: 280px;
    height: 150px;
    border: var(--text-color) 2px solid;
    border-radius: 5%;
}

.title-message {
    font-weight: bold;
    font-size: large;
    color: var(--text-color);
}

.point-ab-text {
    font-size: 14px;
}

.point-ab-title {
    font-size: large;
}

.point-ab-plate {
    justify-content: center;
    display: flex;
    font-size: 30px;
}

.point-ab-plate-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
