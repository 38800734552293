.layout-footer {
    display: flex;
    transition: margin-left $transitionDuration;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
}